import React, { useEffect } from "react";
import { Router } from "@reach/router";
import { graphql } from "gatsby";
import { useDispatch } from "react-redux";

import { renderRoutes, ROUTES_MAP } from "../../apps/routes";
import { ISitePageContext } from "../../models/site-page.model";
import { initFillSurvey } from "../../redux/survey-fill/survey-fill.actions";

export interface IAppProps {
    pageContext: ISitePageContext;
}

export default function App({ pageContext }: IAppProps) {
    const dispatch = useDispatch();
    const { routeKey } = pageContext;
    const routesMap = renderRoutes([ROUTES_MAP[routeKey]], ROUTES_MAP[routeKey].Component);

    useEffect(() => {
        if (routeKey === "SURVEY") {
            dispatch(initFillSurvey());
        }
    }, [dispatch, routeKey]);

    return <Router>{routesMap}</Router>;
}

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
